




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ImplementationProcessResource } from '@/models/implementations/processes/ImplementationProcessResource'

import ProcessIndexListItem from './ProcessIndexListItem.vue'

@Component({
  components: {
    ProcessIndexListItem
  }
})
export default class ProcessIndexList extends Vue {

  @Prop()
  private process!: ImplementationProcessResource

  @Prop()
  private proposalRouteName!: string

}
