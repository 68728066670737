






























import { Component, Vue, Prop } from 'vue-property-decorator'
import ScoreCard from '@/components/misc/Scorecard.vue'
import ProposalCard from '@/components/implementation/proposal/ProposalCard.vue'

import { ImplementationProcessResource } from '@/models/implementations/processes/ImplementationProcessResource'
import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import {
  ImplementationEntityProcessResource,
  isImplementationEntityProcessResource,
} from '@/models/implementations/processes/ImplementationEntityProcessResource'

@Component({
  components: {
    ScoreCard,
    ProposalCard,
  },
})
export default class ProcessList extends Vue {
  @Prop()
  private process!:
    | ImplementationProcessResource
    | ImplementationEntityProcessResource

  @Prop()
  private proposalRouteName!: string

  private get isQuestionRoute(): boolean {
    if (this.$route.name === 'implementation-questions') {
      return true
    }

    return false
  }

  private isProposalInactive(
      proposal: ImplementationProposalResource,
  ): boolean {
    if (this.$route.params.proposal_id) {
      return +this.$route.params.proposal_id !== proposal.id
    }
    return false
  }

  private get isEntityProcess(): boolean {
    return isImplementationEntityProcessResource(this.process)
  }
}
