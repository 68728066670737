

















import { Component, Vue, Prop } from 'vue-property-decorator'
import ScoreCard from '@/components/misc/Scorecard.vue'

import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import {
  ImplementationEntityProposalResource,
  isImplementationEntityProposalResource,
} from '@/models/implementations/proposal/ImplementationEntityProposalResource'

@Component({
  components: {
    ScoreCard,
  },
})
export default class ProposalCard extends Vue {
  @Prop()
  private proposal!:
    | ImplementationProposalResource
    | ImplementationEntityProposalResource

  private get isEntityProposal(): boolean {
    return isImplementationEntityProposalResource(this.proposal)
  }
}
