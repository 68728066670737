



















import { Component, Vue, Prop } from 'vue-property-decorator'
import ScoreCard from '@/components/misc/Scorecard.vue'

import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import {
  ImplementationEntityProposalResource,
  isImplementationEntityProposalResource,
} from '@/models/implementations/proposal/ImplementationEntityProposalResource'

@Component({
  components: {
    ScoreCard,
  },
})
export default class ProcessIndexListItem extends Vue {
  @Prop()
  private proposal!:
    | ImplementationProposalResource
    | ImplementationEntityProposalResource

  @Prop()
  private proposalRouteName!: string

  private get isEntityProposal(): boolean {
    return isImplementationEntityProposalResource(this.proposal)
  }

  private isProposalActive(
      proposal: ImplementationProposalResource,
  ): boolean {
    if (this.$route.params.proposal_id) {
      return +this.$route.params.proposal_id === proposal.id
    }
    return false
  }
}
